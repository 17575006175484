import { render, staticRenderFns } from "./player.vue?vue&type=template&id=59fe7d36&scoped=true&"
import script from "./player.vue?vue&type=script&lang=js&"
export * from "./player.vue?vue&type=script&lang=js&"
import style0 from "./player.vue?vue&type=style&index=0&id=59fe7d36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59fe7d36",
  null
  
)

export default component.exports