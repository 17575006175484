<template>
  <div class="cg-video cg-row">
    <div class="cg-video-content cg-row">
      <!--播放器开始-->
      <div class="cg-video-left">
        <vue-aliplayer-v2
          :source="source"
          ref="VueAliplayerV2"
          :options="options"
          @ended="handle('ended')"
        />
      </div>
      <!--播放器结束-->
      <!--视频集数列表开始-->
      <div class="cg-video-right cg-video-list">
          <div class="cg-video-list-title">{{ album.title }} <span class="cg-video-list-counts">共 {{album.total}} 集</span></div>
          <ul class="cg-video-list-content">
            <li
              @click="changePlay(index)"
              v-for="(item, index) in list"
              :key="index"
              v-bind:class="[ playItem.id == item.id ? 'selected' : '']"
            >{{ item.episode }}</li>
          </ul>
      </div>
      <!--视频集数列表结束-->
      <!--视频播放工具开始-->
      <div class="cg-video-left">
        <div class="cg-video-playing-title">{{ playItem.title }}</div>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="12" >
            <div class="cg-video-info-albmu-title" >{{ album.title }}</div>
          </el-col>
          <el-col :span="12">
            <ul class="cg-video-info-tools">
              <li @click="share">
                <el-button plain icon="iconfont icon-hk-share"> 分享</el-button>
              </li>
              <li @click="download">
                <el-button plain icon="iconfont icon-hk-download"> 下载</el-button>
              </li>
            </ul>            
          </el-col>
        </el-row>
      </div>
      <!--视频播放工具结束--> 
      <div class="cg-video-left margin-tb-20">
        <el-divider>字幕文档</el-divider>
        <el-skeleton :rows="10" animated v-if="content==''"/>
        <div class="cg-video-text" v-html="content"></div>
      </div>
      <!--视频播放工具结束--> 
    </div>
    <el-dialog
      title="分享"
      :visible.sync="shareVisible"
      class="cg-share-dialog"
      width="600px">
      <div class="cg-share-dialog-title">{{ playItem.title }}</div>
      <div>
        <el-form :inline="true" >
          <el-form-item label="">
            <el-input v-model="shareUrl" placeholder="https://" class="cg-share-dialog-input"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button round @click="copyUrl($event)">复制</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shareVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// require styles
import { getLectureVideoList,getVideoText } from '@/common/api_cg';
import clip from '@/utils/clipboard' 
import {
  getVideoNumUrl,
  video_url,
  audio_url,
  //video_image_url
} from "@/common/tools";
export default {
  name: "VideoPlayer",
  data() {
    return {
      options: {
        // source:'//player.alicdn.com/video/aliyunmedia.mp4',
        isLive: false, //切换为直播流的时候必填
        // format: 'm3u8'  //切换为直播流的时候必填
        height: '520px',
        width: '100%',
        cover:''
      },
      source: "//player.alicdn.com/video/aliyunmedia.mp4",
      show: false,
      repeat: false,
      isLive: false,
      isLoadData: false,
      list:[],
      pageList:[],
      playItem:{
        playIndex:0,
        id:0,
      },
      activePage:0,
      videoPageSize:50,
      album:{
        title:'',
        id:0,
      },
      content: '',
      shareVisible: false,
      shareUrl:'https://',
    };
  },
  watch: {
  },
  computed: {
    Options() {
      return this.options;
    },
  },
  methods: {
    onLoad(){
      this.isLoadData = true
      this.list = []
      getLectureVideoList({num:this.album.num}).then(res => {
          this.list = res.data.rows
          this.isLoadData = false
          this.album = res.data.album
          if(this.playItem.id > 0 ){
              for (let i = 0; i < this.list.length; ++i) {
                  if(this.playItem.id == this.list[i].id){
                      this.playItem.playIndex = i 
                      continue;
                  }
              }
          }else{
            this.playItem.id = this.list[0].id
          }   
          //this.pageList = getPageView(this.list.rows.length);
          //this.changePage();
          //this.changeEepisode(this.playItem.playIndex);
          this.changePlay(this.playItem.playIndex)
            
      })    
    },
    handle(e) {
      if(e == 'ended'){
        if(this.playItem.playIndex < this.list.length){
          this.playItem.playIndex ++
        }else{
          this.playItem.playIndex = 0
        }
        this.changePlay(this.playItem.playIndex)
      }
      console.log(`ready`, e);
    },
    changePlay(index) {
      this.playItem = this.list[index]
      this.playItem.playIndex = index
      //this.options.cover = video_image_url + 'image/special/'+ this.album.num + '.jpg'
      if(this.album.mp4){
        this.source = getVideoNumUrl(video_url, this.playItem.num, 'm3u8')
      }else{
        this.source = getVideoNumUrl(audio_url, this.playItem.num, 'mp3')
      }

      this.text()
      this.shareUrl = `/lecture/player/${this.album.num}/${this.playItem.id}`
      if(window.location.pathname !== this.shareUrl){
          this.$router.push({ path: this.shareUrl  })
      }
    },
    share(){
      this.shareUrl = window.location.href
      this.shareVisible = true
    },
    copyUrl($event){
      clip(this.shareUrl,$event)
    },
    download(){
      let url = this.source
      window.open(url);
    },
    text(){
        getVideoText({num:this.playItem.num}).then(res => {
          this.content = res.data.content
        })
    }
  },
  async created() {
    //獲取路由ID
    if (this.$route.params.num) {
      this.album.num = this.$route.params.num;
    }
    if (this.$route.params.itemid) {
      this.playItem.play_id = this.$route.params.itemid;
    }
    this.onLoad()
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  metaInfo: {
    title: "視頻播放",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
      }
    ]
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cg-video-content {
  margin-bottom: 10px;
  background-color: #fbfbfb;
}
.cg-video-left {
  float: left;
  width: 930px;
}
.cg-video-right {
  float: right;
  width: 340px;
}

.cg-video-list-title {
  line-height: 30px;
  font-size: 18px;
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}


.cg-video-list-counts {
  text-align: right;
  font-size: 14px;
  padding: 0 10px;
}

.cg-video-list-content {
  background-color: #fbfbfb;
  height: 450px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0 0 0 15px;
}
.cg-video-list-content li {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  margin: 4px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #eee;
  cursor: pointer;
}
.cg-video-list li:hover {
  background-color: #ececec;
}
.cg-video-list li.selected {
  color: #fff;
  background-color: #436ab3;
}
.el-breadcrumb {
  font-size: 18px;
  padding: 0px 0;
  margin-top: 20px;
}
.el-breadcrumb__item {
  font-size: 18px;
  line-height:30px;
}

.cg-video-info-date {
  float: left;
  height: 30px;
  line-height: 30px;
}
.cg-video-info-date span {
  padding-left: 20px;
}
.cg-tools {
  float: right;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
}
.cg-tools li {
  float: right;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  border: 1px solid #eee;
  padding: 0px 10px;
  margin-left: 5px;
  margin-bottom: 3px;
}
.like {
  color: red;
}
.playlist {
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
}
.playlist-item {
  font-weight: 700;
  position: relative;
  padding: 5px;
  display: block;
}

.add-playlist {
  padding: 10px;
  font-weight: 700;
}

.cg-video-text {
  text-align:justify;
  font-size: 18px;
  line-height: 35px;
  margin:10px 0;
  padding:10px 0px;
}

.cg-video-playing-title{
    font-size: 20px;
    margin:15px 0;
}

.cg-video-info-albmu-title{
  font-size: 20px;
  font-weight: 500;
}

.cg-video-info-tools li{
  float: right;
  margin-left: 10px;
}

.cg-share-dialog-title{
  margin:10px 0;
}

.cg-share-dialog-input{
  width:450px;
}
</style>
